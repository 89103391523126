import React from "react";
import { useNavigate } from "react-router-dom";

const OpenTraining = ({row, children}) => {
   const navigate = useNavigate();

    return (
        <span onClick={(e) => {
          row?.pif_document  ? e.preventDefault() :
          navigate(`?t=${row.id}&pm=s`)}}>
            {children}
        </span>
    )

};

export default OpenTraining;
