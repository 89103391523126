import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAttendances } from "../lessonsSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClipboard, faLock, faTimes, faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
export default function StudentList({ lesson_id }) {

  const attendanceList = useSelector((state) => state?.lessons.attendances)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAttendances({ lesson_id }))
  }, [])

  const renderAttendingState = (state) => {
    if (state == "show") {
      return <FontAwesomeIcon icon={faCheck} color="#1ABC9C" />
    } else if (state == 'noshow') {
      return <FontAwesomeIcon icon={faTimes} color="#d16a6b" />
    } else {
      return <></>
    }
  }

  return (
    <div className={`event-detail`}>
      {
        attendanceList?.map((item, index) => (
          <div key={index}>
            {item?.name} {renderAttendingState(item["state"])}
          </div>
        ))
      }
    </div>
  );
}
