import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchLessons = createAsyncThunk(
  'user/fetchLessons',
  async ({filters, trainingId=null}) => {
    try {
      const response = await axios.get(`/api/v2/lessons?filters=${JSON.stringify(filters)}${trainingId ? `&training_id=${trainingId}` : ''}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return response.data
    } catch (error) {
      return console.log(error.message)
    }
  }
)

export const getLesson = createAsyncThunk(
    'user/getLesson',
    async (id) => {
      try {
        const response = await axios.get(`/api/v2/lessons/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const getStateForm = createAsyncThunk(
    'user/getStateForm',
    async ({id, wanted_state, student}) => {
      try {
        const response = await axios.get(`/api/v2/lessons/${id}/state_form?wanted_state=${wanted_state}&student=${student}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const fetchFuturesLessons = createAsyncThunk(
    'user/fetchFuturesLessons',
    async ({futurePage, filters, trainingId=null}) => {
      try {
        const response = await axios.get(`/api/v2/lessons/future_lessons?page=${futurePage}${trainingId ? `&training_id=${trainingId}` : ''}&filters=${JSON.stringify(filters)}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const fetchPassedLessons = createAsyncThunk(
    'user/fetchPassedLessons',
    async ({passedPage, filters, trainingId=null}) => {
      try {
        const response = await axios.get(`/api/v2/lessons/past_lessons?page=${passedPage}${trainingId ? `&training_id=${trainingId}` : ''}&filters=${JSON.stringify(filters)}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const fetchCalendarLessons = createAsyncThunk(
    'user/fetchCalendarLessons',
    async (props) => {
      try {
        const response = await axios.get(`/api/v2/lessons/calendar_lessons?filters=${JSON.stringify(props)}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const createLesson = createAsyncThunk(
    'user/createLesson',
    async ({availability_id, student_id}, thunkAPI) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      try {
        const response = await axios.post(`/api/v2/lessons?availability_id=${availability_id}&student_id=${student_id}`, [], {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
          if (!error.response) {
            throw error
          }
          return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )

  export const createCollectiveLesson = createAsyncThunk(
    'user/createCollectiveLesson',
    async ({availability, params, teacherId}, thunkAPI) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      const data = {availability, params, teacherId}
      try {
        const response = await axios.post(`/api/v2/lessons/create_collective`, data, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
          if (!error.response) {
            throw error
          }
          return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )

export const manageLesson = createAsyncThunk(
  'user/manageLesson',
  async ({ lesson_id, to_state, ticket = {} }) => {
    const form = new FormData();
    form.append("ticket[app_document_attributes][file]", ticket.app_document);
    form.append("ticket[expected]", to_state);
    if (ticket.note) {
      form.append("ticket[note]", ticket.note);
    }
    if (ticket.requestReason) {
      form.append("ticket[recredit_reason]", ticket.requestReason);
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    try {
      const response = await axios.patch(`/api/v2/lessons/${lesson_id}/manage_lesson?to_state=${to_state}`, form, {
        headers: {
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
      });
      return response.data;
    } catch (error) {
      console.error(error.message);
    }
  }
);

  export const cancelLesson = createAsyncThunk(
    'user/cancelLesson',
    async ({lesson_id, ticket={}, to_state}) => {
      const form = new FormData();
      if (ticket.note) {
        form.append("ticket[note]", ticket.note);
      }
      if (ticket.requestReason) {
        form.append("ticket[recredit_reason]", ticket.requestReason);
      }
      if (to_state) {
        form.append("to_state", to_state)
      }

      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      try {
        const response = await axios.patch(`/api/v2/lessons/${lesson_id}/cancel`, form, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const teacherCancelRequest = createAsyncThunk(
    'user/teacherCancelRequest',
    async ({lesson_id, student_id}) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      const body = {
        lesson_id: lesson_id,
        student_id: student_id,
      }
      try {
        const response = await axios.put(`/api/v1/teachers/send_cancel_request`, body, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const adminCollectiveBooking = createAsyncThunk(
    'user/adminCollectiveBooking',
    async ({lesson_id, student_id, training_id},thunkAPI) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      const body = {
        lesson_id: lesson_id,
        student_id: student_id,
        scope: "lesson"
      }

      try {
        const response = await axios.post(`/api/v2/attendances`, body, {
          headers: {
            'Content-Type': 'application/json',
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        if (!error.response) {
          throw error
        }
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  )

  export const adminCollectiveCanceling = createAsyncThunk(
    'user/adminCollectiveCanceling',
    async ({lesson_id, student_id, attendance_id}) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;


      try {
        const response = await axios.delete(`/api/v2/attendances/${attendance_id}`, {
          headers: {
            'Content-Type': 'application/json',
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const postReview = createAsyncThunk(
    'user/postReview',
    async ({review, lesson_id}) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      try {
        const response = await axios.post(`/api/v2/reviews`, {review, lesson_id}, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const validateReview = createAsyncThunk(
    'user/validateReview',
    async ({review, attendings}) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
      try {
        const response = await axios.patch(`/api/v2/reviews/${review.id}/validate_review`, {review, attendings}, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

  export const getAttendances = createAsyncThunk(
    'user/getAttendances',
    async ({lesson_id}) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;

      try {
        const response = await axios.get(`/api/v2/lessons/${lesson_id}/attendances`, {
          headers: {
            'Content-Type': 'application/json',
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        })
        return response.data
      } catch (error) {
        return console.log(error.message)
      }
    }
  )

const lessonsSlice = createSlice({
  name: 'lessons',
  initialState: {
    loading: false,
    lessonsList: [],
    hasMoreFuture: true,
    hasMorePassed: true,
    lessonsCal: [],
    lessonShow: {},
    stateForm: {},
    currentRequestId: "",
    error: '',
    attendances:[]
  },
  reducers: {
    setLessonsList: () => {
      state.lessonsList = action.payload
    },
    setLessonShow: () => {
      state.lessonShow = action.payload
    },
    setStateForm: () => {
      state.stateForm = action.payload
    },
    setFutureLessons: () => {
        state.lessonsList = [...action.payload, ...state.lessonsList]
    },
    setPassedLessons: () => {
      state.lessonsList = [...state.lessonsList, ...action.payload]
    },
    setCalendarLessons: () => {
      state.lessonsCal = action.payload
    },
    setPendingRequestId(state, action) {
      state.pendingRequestId = action.payload;
    },
    resetLessonError: (state, action) => {
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLessons.pending, (state, action) => {
      if (!state.loading) {
        state.loading = true
        state.currentRequestId = action.meta.requestId
      }
    }),
    builder.addCase(fetchLessons.fulfilled, (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading &&
        state.currentRequestId === requestId
      ) {
        state.loading = false
        state.lessonsList = action.payload
        state.currentRequestId = undefined
      }
    }),
    builder.addCase(fetchLessons.rejected, (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading &&
        state.currentRequestId === requestId
      ) {
        state.loading = false
        state.error = action.error
        state.currentRequestId = undefined
      }
    }),
    builder.addCase(getLesson.fulfilled, (state, action) => {
        state.lessonShow = action.payload
    }),
    builder.addCase(getStateForm.fulfilled, (state, action) => {
      state.stateForm = action.payload
    }),
    builder.addCase(fetchFuturesLessons.pending, (state, action) => {
      state.loading = true
    }),
    builder.addCase(fetchFuturesLessons.fulfilled, (state, action) => {
      state.lessonsList = [...action.payload, ...state.lessonsList]
      state.loading = false
    }),
    builder.addCase(fetchPassedLessons.pending, (state, action) => {
      state.loading = true
    }),
    builder.addCase(fetchPassedLessons.fulfilled, (state, action) => {
      state.lessonsList = [...state.lessonsList, ...action.payload]
      state.loading = false
    }),
    builder.addCase(fetchCalendarLessons.fulfilled, (state, action) => {
      state.lessonsCal = action.payload
    }),
    builder.addCase(manageLesson.fulfilled, (state, action) => {
      // calendar update
      let oldCalList = state.lessonsCal.filter(e => {
        return e.id === action.payload.id
      })
      const indexCal = state.lessonsCal.indexOf(oldCalList[0])
      let copiedStateCal = state.lessonsCal.slice(0);
      copiedStateCal[indexCal] = action.payload
      state.lessonsCal = copiedStateCal;

      // list update
      let oldList = state.lessonsList.filter(e => {
        return e.id === action.payload.id
      })

      if (oldList[0]) {
        const index = state.lessonsList.indexOf(oldList[0])
        let copiedState = state.lessonsList.slice(0);
        let newItem = oldList[0]
        newItem.state = action.payload.extendedProps.lesson_state
        copiedState[index] = newItem
        state.lessonsList = copiedState;
      }
    }),
    builder.addCase(createLesson.fulfilled, (state, action) => {
      state.lessonsCal = [action.payload, ...state.lessonsCal]
    }),
    builder.addCase(createLesson.rejected, (state, action) => {
      state.error = action.payload.error
    }),
    builder.addCase(createCollectiveLesson.fulfilled, (state, action) => {
      state.lessonsCal = [action.payload, ...state.lessonsCal]
    }),
    builder.addCase(createCollectiveLesson.rejected, (state, action) => {
      state.error = action.payload.error
    }),
    builder.addCase(postReview.pending, (state, action) => {
      state.loading = true

    }),
    builder.addCase(postReview.fulfilled, (state, action) => {
      state.lessonShow = action.payload
      state.loading = false

    }),
    builder.addCase(validateReview.fulfilled, (state, action) => {


      let oldList = state.lessonsList.filter(e => {
        return e.id === action.payload.id
      })

      if (oldList[0]) {
        const index = state.lessonsList.indexOf(oldList[0])
        let copiedState = state.lessonsList.slice(0);
        copiedState[index] = action.payload
        state.lessonsList = copiedState;
        state.lessonShow = action.payload
      }

    }),
    builder.addCase(cancelLesson.fulfilled, (state, action) => {

      if (action.payload.extendedProps.lesson_state === "canceled" || action.payload.extendedProps.lesson_state === "late_cancelation") {

        let oldCalList = state.lessonsCal.filter(e => {
          return e.id === action.payload.id;
        });
        const indexCal = state.lessonsCal.indexOf(oldCalList[0])
        let copiedStateCal = state.lessonsCal.slice(0);
        copiedStateCal[indexCal] = action.payload
        state.lessonsCal = copiedStateCal;

        let oldList = state.lessonsList.filter(e => {
          return e.id === action.payload.id;
        });
        if (oldList[0]) {
          const index = state.lessonsList.indexOf(oldList[0])
          let copiedState = state.lessonsList.slice(0);
          let newItem = oldList[0]
          newItem.state = action.payload.extendedProps.lesson_state
          copiedState[index] = newItem
          state.lessonsList = copiedState;
        }

      } else {


        // calendar update
        let oldCalList = state.lessonsCal.filter(e => {
          return e.id === action.payload.id
        })
        const indexCal = state.lessonsCal.indexOf(oldCalList[0])
        let copiedStateCal = state.lessonsCal.slice(0);
        copiedStateCal[indexCal] = action.payload
        state.lessonsCal = copiedStateCal;


        // list update
        let oldList = state.lessonsList.filter(e => {
          return e.id === action.payload.id
        })

        if (oldList[0]) {
          const index = state.lessonsList.indexOf(oldList[0])
          let copiedState = state.lessonsList.slice(0);
          let newItem = oldList[0]
          newItem.state = action.payload.extendedProps.lesson_state
          copiedState[index] = newItem
          state.lessonsList = copiedState;
        }

      }

    }),
    builder.addCase(adminCollectiveBooking.fulfilled, (state, action) => {
      let oldCalList = state.lessonsCal.filter(e => {
        return e.id === action.payload.id
      })
      const indexCal = state.lessonsCal.indexOf(oldCalList[0])
      let copiedStateCal = state.lessonsCal.slice(0);
      copiedStateCal[indexCal] = action.payload
      state.lessonsCal = copiedStateCal;

    }),
    builder.addCase(adminCollectiveBooking.rejected, (state, action) => {
     state.error = action.payload.message[0]
    }),

    builder.addCase(adminCollectiveCanceling.fulfilled, (state, action) => {
      let oldCalList = state.lessonsCal.filter(e => {
        return e.id === action.payload.id
      })
      const indexCal = state.lessonsCal.indexOf(oldCalList[0])
      let copiedStateCal = state.lessonsCal.slice(0);
      copiedStateCal[indexCal] = action.payload
      state.lessonsCal = copiedStateCal;

    }),
    builder.addCase(getAttendances.fulfilled, (state, action) => {
      state.attendances = action?.payload
    })
  }
})

export const lessonsList = createSelector(
  (state) => ({
    lessonsList: state.lessonsList,
    lessonShow: state.lessonShow,
    stateForm: state.stateForm,
    lessonsCal: state.lessonsCal,
    error: state.error
  }),
  (state) => state.lessonList
)

export const { setLessonsList, setLessonShow, setFutureLessons, setPassedLessons, setCalendarLessons, setStateForm, resetLessonError } = lessonsSlice.actions
export default lessonsSlice.reducer
