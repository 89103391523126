import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getTraining } from "./trainingsSlice";
import Header from './components/header';
import TrainingCard from './components/trainingCard/fullWidth';
import SidedTrainingCard from './components/trainingCard/sided';
import Chart from './components/chart';
import Lessons from '../lessons/lessons_embeded';
import Elearnings from '../e-learning/elearnings_embeded';
import Summaries from './components/summaries';
import { Tabs, Tab, Button } from 'react-bootstrap';
import StateManagement from "../lessons/components/state_management";
import Modal from '../toolbox/modal'
import i18n from '../../i18n'
import StepOne from "./components/steps/stepOne.js";
import openArrow from "../images/icons/openArrow.svg";
import closeArrow from '../images/icons/closeArrow.svg'
import StepTwo from "./components/steps/stepTwo";
import StepThree from "./components/steps/stepThree";
import StepFour from "./components/steps/stepFour";
import StepFive from "./components/steps/stepFive";
import Document from "./components/document.js";


const Training = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const search = useLocation().search;
  const trainingId = new URLSearchParams(search).get('t') || location.pathname.split("/")[2]
  const panelState = new URLSearchParams(search).get('pm');
  const user = useSelector(state => state.user.userData)
  const training = useSelector(state => state.trainings.trainingShow)
  const [key, setKey] = useState("lessons")
  const openNavbar = useSelector((state) => state.navigations.openNavbar)
  const responsiveOne = useSelector((state) => state.navigations.responsiveOne)
  const navigate = useNavigate()
  const modalState = new URLSearchParams(search).get('pm');
  const ref = useRef()
  const [activeIndex, setActiveIndex] = useState(null)
  const profile = useSelector((state)=>state.user.currentProfile)


  useEffect(() => {
    dispatch(getTraining(trainingId))
  }, [trainingId,profile]);

  const onClick = (value) => {
    setActiveIndex(activeIndex === value ? null : value);
  }

  if (training?.id) {
    if (panelState !== "s") {

      if (responsiveOne) {
        return (
          <div className={`main-content`}>
            <div className={` page-container ${openNavbar && 'responsive-hidden'} ${(panelState == 's' && responsiveOne) && "responsive-panel"}`} >
              <div className="training">
                <div className="training-header">
                  <h3>{training.student} - {training.name}</h3>
                  <p>{training.email} - {training.phone_number}</p>
                </div>
                <div className="training-body">
                  <SidedTrainingCard />

                  <div className="list-infos-steps">
                    <span className="steps-header">{i18n.t('views.trainings.my_training')}</span>
                    <ul className="steps-list-ul">
                      <li className="steps-list-item"  >

                        <div style={{ display: 'flex', flexDirection: 'row' }}>


                          <span style={{ display: 'flex', alignItems: 'center' }} onClick={() => onClick('1')}>
                            <img
                              className="step-icon"
                              src={activeIndex === "1" ? openArrow : closeArrow}
                            />
                          </span>
                          <div className="header-strings">

                            <span onClick={() => onClick('1')} className="info-title"> {i18n.t('views.trainings.chart.video')} </span>

                          </div>
                        </div>


                        {activeIndex === '1' && <StepOne data={training} />}
                      </li>
                      <li className="steps-list-item"  >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                          <span style={{ display: 'flex', alignItems: 'center' }} onClick={() => onClick('2')}>
                            <img
                              className="step-icon"
                              src={activeIndex === "2" ? openArrow : closeArrow}
                            />
                          </span>
                          <div className="header-strings">
                            <span onClick={() => onClick('2')} className="info-title" > {i18n.t('views.trainings.chart.start')} </span>
                            <span className="info-dates">{training?.start_at} </span>
                          </div>
                        </div>

                        {activeIndex === '2' && <StepTwo data={training} />}

                      </li>

                      <li className="steps-list-item" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                          <span style={{ display: 'flex', alignItems: 'center' }} onClick={() => onClick('3')}>
                            <img
                              className="step-icon"
                              src={activeIndex === "3" ? openArrow : closeArrow}
                            />
                          </span>
                          <div className="header-strings">
                            <span onClick={() => onClick('3')} className="info-title"> {i18n.t('views.trainings.chart.midterm')} </span>
                            <span className="info-dates"> {training?.certifying_end_at} </span>
                          </div>
                        </div>

                        {activeIndex === '3' && <StepThree data={training} />}



                      </li>


                      <li className="steps-list-item" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>


                          <span style={{ display: 'flex', alignItems: 'center' }} onClick={() => onClick('4')}>
                            <img
                              className="step-icon"
                              src={activeIndex === "4" ? openArrow : closeArrow}
                            />
                          </span>
                          <div className="header-strings">
                            <span onClick={() => onClick('4')} className="info-title" > {i18n.t('views.trainings.chart.overview')} </span>

                          </div>

                        </div>


                        {activeIndex === '4' && <StepFour data={training} />}
                      </li>

                      <li className="steps-list-item" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                          <span style={{ display: 'flex', alignItems: 'center' }} onClick={() => onClick('5')}>
                            <img
                              className="step-icon"
                              src={activeIndex === "5" ? openArrow : closeArrow}
                            />
                          </span>
                          <div className="header-strings">
                            <span onClick={() => onClick('5')} className="info-title"> {i18n.t('views.trainings.chart.end')} </span>
                            <span className="info-dates"> {training?.end_at} </span>
                          </div>
                        </div>

                        {activeIndex === '5' && <StepFive data={training} />}

                      </li>

                    </ul>
                  </div>

                </div>

              </div>
            </div>
          </div>




        )

      } else {
        return (

          <div className={`main-content`}>
            <div className={` page-container ${openNavbar && 'responsive-hidden'} `} >

              <div className={`training`}>
                <div className="main-header">
                  <div className="main-title">
                    <h2>{training.student} - {training.name}</h2>
                  </div>
                  <div className="main-actions">
                  <Button target="_blank" href={training?.reclosing_link}>{i18n.t('views.trainings.upsell')}</Button>
                  </div>
                </div>

                <div className="training-body">
                  <TrainingCard />
                  <Chart />
                </div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className={` page-container ${openNavbar && 'responsive-hidden'} ${(panelState == 's' && responsiveOne) && "responsive-panel"}`} >

          <div className="training">
            <div>
              <h3>{training.student} - {training.name}</h3>
              <p style={{'marginBottom': '10px'}}>{training.email} - {training.phone_number}</p>
            </div>
            <div>
              <SidedTrainingCard />

              <div className="tabs-container">
                <Tabs
                  defaultActiveKey="lessons"
                  id="uncontrolled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                >
                  <Tab eventKey="overviews" title={`${i18n.t('overviews')}`}>
                    {(key == "overviews") &&
                      <Summaries training={training} path={location?.pathname} />
                    }
                  </Tab>
                  {
                     user.role == "Admin" &&
                    <Tab eventKey="documents" title={`${i18n.t('documents.document')}`}>
                      {(key == "documents") &&
                        <Document training={training} />
                      }
                    </Tab>
                  }
                   <Tab eventKey="elearnings" title={`${i18n.t('elearnings')}`}>
                    {(key == "elearnings") &&
                      <Elearnings />
                    }
                  </Tab>
                  <Tab eventKey="lessons" title={`${i18n.t('navigation.admin.lessons')}`}>
                    {(key == "lessons") &&
                      <Lessons />
                    }
                  </Tab>


                </Tabs>
              </div>

            </div>

            <Modal ref={ref}>
              <StateManagement />
            </Modal>
          </div>
        </div>
      )
    }
  }
  // to update with data from controller & translation
  return (
    <div className={`main-content`}>
      <div className={` page-container ${openNavbar && 'responsive-hidden'} `} >

        <div className="training">
          {i18n.t('loading')}...
        </div>
      </div>
    </div>
  )

};

export default Training;
