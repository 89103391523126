import React, {useEffect, useState, useRef, useContext} from "react";
import { useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { fetchLessons, fetchFuturesLessons, fetchPassedLessons } from "./lessonsSlice";
import { setFiltersLesson } from "../filters/filtersSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DataTable from '../toolbox/datatable';
import EmbededColumns from './columns/embeded';
import Header from './components/header';
import conditionalRowStyles from "./components/conditional_row_styles";
//column rendering depending of role / sided

const Lessons = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const search = location.search;
    const path = location.pathname
    const filters = useSelector(state => state.filters.filtersLesson);
    const trainingId = new URLSearchParams(search).get('t');
    const user = useSelector(state => state.user.userData);
    const { lessonsList, loading } = useSelector((state) => state.lessons)
    const [currentRow, setCurrentRow] = useState(null);
    const [columns, setColumns] = useState(EmbededColumns);
    const [futurePage, setFuturePage] = useState(null);
    const [passedPage, setPassedPage] = useState(null);
    const ref = useRef(null);
    const { store } = useContext(ReactReduxContext)
    const state = store.getState();


    useEffect(() => {
        if ((user.role)){
            //dispatch(setFiltersLesson({trainings: [trainingId]}))
            setFuturePage(null)
            setPassedPage(null)
        }
    }, [trainingId]);


    useEffect(() => {
        setTimeout(() => {
            //const state = store.getState();
            //const filters = state.filters.filtersLesson
            if (trainingId){
                dispatch(fetchLessons({filters, trainingId})).then(() => {
                    setFuturePage(2)
                    setPassedPage(2)
                })

            }
        }, 200)
    }, [trainingId, user]);


    useEffect(() => {
        const state = store.getState();
        const filters = state.filters.filtersLesson

        if (passedPage && !loading) {
            dispatch(fetchPassedLessons({passedPage, filters, trainingId}))
        }
    }, [passedPage]);

    useEffect(() => {
        const state = store.getState();
        const filters = state.filters.filtersLesson
        if (futurePage && !loading) {
            dispatch(fetchFuturesLessons({futurePage, filters, trainingId}))
            setTimeout(() => {
            }, 400);
        }
    }, [futurePage]);

    useEffect(() => {
        (currentRow && ['done', 'passed'].includes(currentRow.state)) &&
            navigate(`/lessons?l=${currentRow?.id}&pm=s`)
    }, [currentRow]);

    return (
        <div className="lessons embeded">
            <div className={`datatable page-container ${path?.includes('trainings-view') ?  'embeded-tv' : 'embeded' }`} >

                <DataTable
                    columns={columns}
                    data={lessonsList}
                    onRowClicked={(row) => setCurrentRow(row)}
                    setPassedPage={setPassedPage}
                    passedPage={passedPage}
                    loading={loading}
                    futurePage={futurePage}
                    setFuturePage={setFuturePage}
                    ref={ref}
                    conditionalRowStyles={conditionalRowStyles}
                />

            </div>
        </div>
    )

};

export default Lessons;
