import React, { useEffect, useRef, useState } from "react"
import DataTable from "../../toolbox/datatable"
import Columns from '../columns/documentColumns';
import { useDispatch, useSelector } from "react-redux";
import { dlDocuments, getDocuments } from "../trainingsSlice";
import { Button, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import i18n from '../../../i18n'
import ListItem from "./listItem";


export default function DocumentList({ documents }) {


  return (
    <div>
      {documents?.length > 0 ? (
        documents.map((item, index) => (
          <ListItem
            key={index}
            label={`${i18n.t(`documents.${item?.document_type}`)}`}
            link={item?.url}
            value={(item?.url !== '#' && item?.url) ? <div className={(item?.url !== '#' && item?.url) && "general-icon small downloadTraining"}></div> : ''}
            status={"active"}
          />
        ))
      ) : (
        <div></div>
      )}
    </div>
  );


}
