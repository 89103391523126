
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getAttendances } from "../lessonsSlice";
import StudentList from "./studentList";

export default function StudentListTooltip({ lesson_id, name, state }) {

  const overlay = (
    <Popover id="popover-basic">
      <StudentList lesson_id={lesson_id} />
    </Popover>
  )

  if (state === "booked" || state === "passed" || state === "noshow" || state === "done") {
    return (
      <div>
        <OverlayTrigger
          trigger="hover"
          placement="right"
          containerPadding={20}
          overlay={
            overlay
          }
        >
          <span> {name} </span>
        </OverlayTrigger>
      </div>
    );
  } else {
    return <span> {name} </span>
  }
  

}
