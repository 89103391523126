
import React, { useEffect, useState } from "react"
import ExerciseContent from "./exerciseContent"

import openArrow from '../../images/icons/openArrow.svg'
import closeArrow from '../../images/icons/closeArrow.svg'

export default function SubStep({ setCurrentRow,data,openedSubStep,setOpenedSubStep,index, currentRow }) {

  const [controlArrow, setControlArrow] = useState(null)

  const [color, setColor] = useState("black");



  const onClickOpened = (nodeId) => {
    setOpenedSubStep((prevState) =>
      prevState.includes(nodeId)
        ? prevState.filter((id) => id !== nodeId)
        : [...prevState, nodeId]
    );
  };

  useEffect(() => {
    const checkColorByAssignee = () => {

      //sarı
      //state boş, assigne ?? assignment var
      let conditionYellow =
        data?.exercices?.some((exercice) =>
          exercice?.assignment !== null && (exercice?.state === 'assign' || exercice?.state === "")
        )

      //mavimsi
      //state done assigne var
      let conditionGreen =
        data?.exercices?.some((exercice) =>
          exercice?.assignment !== null && exercice?.state === 'done'
        )
        let conditionGreenAll =
        data?.exercices?.every((exercice) =>
          exercice?.assignment !== null && exercice?.state === 'done'
        )

        let conditionBlack =
        data?.exercices?.some((exercice) =>
          exercice?.assignment === null && exercice?.state === null
        )



      if (conditionYellow) {
        return 'yellow';
      }
      else if (conditionGreenAll) {
        return 'green';
      }
       else if(conditionGreen && conditionYellow){
        return 'yellow';
      }
      else if(conditionGreen && conditionBlack){
        return 'green'
      }
      else {
        return 'black';
      }
    };

    setColor(checkColorByAssignee());
  }, [data]);

  return (
    <div className="substep-container">

      <li className="li" >
        <div className="substep-header" >
          {data?.exercices && (
            <span onClick={ ()=>{onClickOpened(index)} }>
              {openedSubStep.includes(index) ? <img className="icon" src={openArrow} /> : <img className="icon" src={closeArrow} />}
            </span>
          )}
          <span onClick={ ()=>{onClickOpened(index)} } className={`substep-thematic ${color}`}>{data?.subcategory}</span>

          <span onClick={ ()=>{onClickOpened(index)} } style={{marginLeft:'48px'}} className={`substep-thematic-count ${color}`}>{data?.count_label}</span>

        </div>
        {data?.exercices && openedSubStep.includes(index) && (
          <ul className="tree">
            {data?.exercices?.map((item, index) => (

              <ExerciseContent key={index} setCurrentRow={setCurrentRow} data={item} index={index} setControlArrow={setControlArrow} controlArrow={controlArrow} currentRow={currentRow}/>
            ))}
          </ul>
        )}
      </li>

    </div>

  )
}
