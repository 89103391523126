import React, { useContext, useEffect, useState } from "react"
import { ReactReduxContext, useDispatch, useSelector } from "react-redux"
import { fetchExercises } from "./elearningSlice"
import Steps from "./components/steps"
import { useLocation, useNavigate } from "react-router-dom"
import i18n from "../../i18n"

export default function ELearnings() {

  const location = useLocation();
  const search = location.search;
  const trainingId = new URLSearchParams(search).get('t');
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.filters.filtersELearning)
  const loading = useSelector((state) => state.elearnings.loading)
  const exercices = useSelector((state) => state.elearnings.exercisesList)
  const [openedNode, setOpenedNode] = useState([]);
  const user = useSelector(state => state.user.userData);
  const { store } = useContext(ReactReduxContext)
  const training = useSelector((state)=>state.trainings.trainingShow)


  useEffect(() => {
    dispatch(fetchExercises({filters: filters, trainingId: trainingId || training?.id}))
  }, [trainingId])

  useEffect(() => {
    const state = store.getState();
    const filters = state.filters.filtersELearning
    dispatch(fetchExercises({filters: filters, trainingId: trainingId}))
  }, [filters, user]);


  if (loading) {
    return (
      <div className="elearning-page embeded pending-query">
         {i18n.t('pending_data')}
      </div>
    )
  }

  if (exercices) {
    return (
          <div className="elearning-page embeded">
            {
              exercices?.map((item, index) => {
                return (
                  <Steps index={index} key={index} node={item} openedNode={openedNode}
                    setOpenedNode={setOpenedNode} />
                )
              })
            }
          </div>
    )
  }

  return (
    <></>
  )

}
