import { useDispatch, useSelector } from "react-redux";
import LessonTransition from './lessonTransition';

import React, { useEffect, useState, useRef } from "react";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import EventDetail from './event_detail';
import i18n from "../../../i18n";
import { useLocation } from "react-router-dom";


const Status = ({ id, status,name }) => {

  const location = useLocation();
  const search = location.search;
  const lessonId = new URLSearchParams(search).get('l') || location.pathname.split("/")[2];
  const user = useSelector((state)=>state.user.userData)

  const overlay = (
    <Popover id="popover-basic">
      <LessonTransition event={lessonId || id} state={status} />
    </Popover>
  )

  const popperConfig =( ( location?.pathname?.includes('lessons') && !location?.search?.includes('pm')))
    ? {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-450, 10],

            },
          },
          {
            name: 'arrow',
            options: {
              padding: 100,
            },
          },
        ],
      }
    : {};


  if (status || (status===null && user?.role ==='Admin' && name==='collective' )) {
    return (
        <div >
          <OverlayTrigger  key={id} trigger="click" popperConfig={popperConfig} placement="bottom" containerPadding={20} rootClose overlay={overlay}>
            <div className="inner-event">

              <span className={`status ${status}`} >{i18n.t(`activerecord.attributes.lesson_transition.state.${status ? status : 'pending'}`)}</span>
            </div>
          </OverlayTrigger>
        </div>
      )
    } else {

        return (
          <div >
              <div className="inner-event">
                <span className={`status ${status}`} >{i18n.t(`activerecord.attributes.lesson_transition.state.${status ? status : 'pending'}`)}</span>
              </div>
          </div>
        )



    }
};

export default Status;
