import React, { useEffect, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import EventDetail from './event_detail';
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import { setUserData } from "../../user/userSlice";
import ActionCable from "actioncable";
import logger from "redux-logger";


const NextLesson = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.userData)

  useEffect(() => {
    const lessonCable = ActionCable.createConsumer('/cable');
    const newChannel = lessonCable.subscriptions.create(
      {
        channel: "LessonsChannel",
      },
      {
        received: (data) => dispatch(setUserData(data.user))
      })

  }, []);

  if (user.next_lesson) {
    if (user.next_lesson.room_link === "nolink_yet") {
      return (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>{i18n.t('views.lessons.waiting_next_lesson')}</Tooltip>}
        >
          <div className="next-lesson">
            <div className="general-icon camera-green medium"></div>
            <div className="next-lesson-content">
              <a target="_blank" href={user.next_lesson.room_link !== "nolink_yet" && user.next_lesson.room_link}>
                <div className="lesson-title">
                  <p className="label">{i18n.t('navigation.student.next_lesson')}</p>
                  <p>{user.next_lesson.other_involved}</p>
                </div>
                <div className="lesson-time">
                  {i18n.t('navigation.student.in')}  {user.next_lesson.time_left}
                </div>
              </a>
            </div>
          </div>
        </OverlayTrigger>
      )
    } else {
      return (
        <div className="next-lesson">
          <div className="general-icon camera-green medium"></div>
          <div className="next-lesson-content">
            <a target="_blank" href={user.next_lesson.room_link !== "nolink_yet" && user.next_lesson.room_link}>
              <div className="lesson-title">
                <p className="label">{i18n.t('navigation.student.next_lesson')}</p>
                <p>{user.next_lesson.other_involved}</p>
              </div>
              <div className="lesson-time">
                {user.next_lesson.time_left === 'Cours débuté' || user.next_lesson.time_left === 'Lesson started'
                  ? user.next_lesson.time_left
                  : `${i18n.t('navigation.student.in')} ${user.next_lesson.time_left}`
                }

              </div>
            </a>
          </div>
        </div>

      )
    }

  }

  return (
    <></>
  )


};


export default NextLesson;
